import styled from 'styled-components';

import { Button as BaseButton } from '@atoms/Button';
import { PageContainer as BasePageContainer } from '@molecules/Containers';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;

  @media ${props => props.theme.query.max.solve(props.theme.breakpoint.lg)} {
    flex-direction: column-reverse;
  }
`;

const ErrorMessageText = styled.div`
  ${props => props.theme.typo.bodyL};
  ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.color.g500};

  @media ${props => props.theme.query.desktop} {
    ${props => props.theme.typo.displayM};
    color: ${props => props.theme.color.black};
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  ${props => props.theme.typo.displayL};
  ${props => props.theme.fontWeight.demi};
  max-width: 450px;

  @media ${props => props.theme.query.max.solve(props.theme.breakpoint.lg)} {
    margin-left: 0;
    ${props => props.theme.typo.bodyL};
    max-width: 270px;
    text-align: center;
    margin: 0 auto;
  }

  ${ErrorMessageText} {
    margin-top: 20px;

    @media ${props => props.theme.query.desktop} {
      margin-top: 15px;
    }
  }
`;

const ErrorCode = styled.div`
  ${props => props.theme.typo.displayL};
  ${props => props.theme.fontWeight.demi};
  color: ${props => props.theme.color.g500};
`;

const Img = styled.img`
  margin-right: 80px;

  @media ${props => props.theme.query.max.solve(props.theme.breakpoint.lg)} {
    margin: 35px 0 25px;
    max-width: 270px;
  }
`;

const LinkContainer = styled.div`
  margin-top: 20px;
`;

const Button = styled(BaseButton)`
  height: 50px;
  min-width: 140px;

  margin-left: auto;
  margin-right: auto;

  ${props => props.theme.typo.bodyM};

  @media ${props => props.theme.query.desktop} {
    margin: 0;
  }
`;

const PageContainer = styled(BasePageContainer)`
  padding: 100px 15px;
`;

export {
  Container,
  Message,
  Img,
  LinkContainer,
  Button,
  ErrorMessageText,
  ErrorCode,
  PageContainer,
};
