import { LayoutDefault } from '@features/layouts/layout-default';
import { PageNotFound } from '@features/page-not-found';
import { SEO } from '@features/seo';
import { gatsbyPageProps } from '@infra/gatsby/types';
import React from 'react';

const NotFoundPage = props => {
  const {
    pageContext: { locale },
    location,
  } = props;

  return (
    <LayoutDefault locale={locale} location={location}>
      <SEO
        location={location}
        isNoIndex
        isNoFollow
        title="404: Not found"
        lang={locale}
      />
      <PageNotFound />
    </LayoutDefault>
  );
};

NotFoundPage.propTypes = {
  ...gatsbyPageProps,
};

export { NotFoundPage };
export default NotFoundPage;
