import { Link } from '@atoms/link';
import { useTranslation } from '@infra/i18n';
import React from 'react';

import imgSrc from './images/404.svg';
import {
  Button,
  Container,
  ErrorCode,
  ErrorMessageText,
  Img,
  LinkContainer,
  Message,
  PageContainer,
} from './styled-components';

const PageNotFound = () => {
  const { t } = useTranslation('pageNotFound');

  return (
    <PageContainer data-testid="feature-page-not-found">
      <Container>
        <Message>
          <ErrorCode>{t('errorCode')}</ErrorCode>
          <ErrorMessageText>{t('title')}</ErrorMessageText>
          <LinkContainer>
            <Link to="/">
              <Button>{t('home')}</Button>
            </Link>
          </LinkContainer>
        </Message>
        <Img src={imgSrc} alt={t('title')} />
      </Container>
    </PageContainer>
  );
};

export { PageNotFound };
